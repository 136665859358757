import { MdOutlineArrowUpward } from 'react-icons/md';
import './LineUp.css';
import { useState } from 'react';
import { useProject } from '../../tools/ProviderContext';

function LineUp() {
  const { notScrollLineUp } = useProject();
  const [isLineUp, setLineUp] = useState(false);

  const scrollUpHeader = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
    notScrollLineUp();
  };
  window.addEventListener('scroll', function () {
    if (
      window.innerHeight + window.scrollY + 100 >=
      document.body.clientHeight
    ) {
      console.log('I am not here');
      setLineUp(true);
    } else if (
      window.innerHeight + window.scrollY <
      document.body.clientHeight
    ) {
      console.log('I am here');
      setLineUp(false);
    }
  });

  return (
    <MdOutlineArrowUpward
      onClick={() => {
        scrollUpHeader();
      }}
      className={isLineUp ? 'lineup-img' : 'lineup-img-none'}
    />
  );
}

export default LineUp;
